<template>
	<div class="container mt-5">
		
		<b-breadcrumb>
			<b-breadcrumb-item href="#home">Home</b-breadcrumb-item>
			<b-breadcrumb-item href="#foo">Foo</b-breadcrumb-item>
			<b-breadcrumb-item href="#bar">Bar</b-breadcrumb-item>
			<b-breadcrumb-item active>Baz</b-breadcrumb-item>
		</b-breadcrumb>
		
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import BBreadcrumb from '@/components/bootstrap/breadcrumb.vue';
import BBreadcrumbItem from '@/components/bootstrap/breadcrumbItem.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Test | IW'});
	},
	computed: {},
	methods: {},
	created(){},
	components: {
		BBreadcrumb,
		BBreadcrumbItem,
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>