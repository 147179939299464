<template>
	<div class="mt-5">
		<div class="container">
			<div class="row mb-5">
				<div class="col-md-12">
					<div class="card h-100">
						<div class="card-header text-center">
							<h3>Достижения</h3>
						</div>
						<div class="card-body">
							<b-overlay :show="achievementsOverlay" rounded="lg" variant="white">
								<div class="accordion mb-3" :id="'accordionFlush'+group.id" v-for="group in groups" :key="group.id">
									<div class="accordion-item">
										<div class="accordion-header" :id="'flush-heading'+group.id">
											<button class="accordion-button p-4 collapsed" type="button" data-bs-toggle="collapse" :data-bs-target="'#flush-collapse'+group.id" aria-expanded="false" :aria-controls="'flush-collapse'+group.id">
												<div class="d-flex w-100 justify-content-between">
													<div class="icon-column me-3">
														<img :src="'https://iwstatic.g.bsrv.su/img/achievements/frames/frame'+ucFirst(getCurrentGroupAchievement(group.id).level)+'.png'" class="achievement-flag" />
														<img :src="'https://iwstatic.g.bsrv.su/img/achievements/icons/'+group.icon" class="achievement-icon" v-if="group.icon" />
													</div>
													<div class="row w-100">
														<div class="col-8 d-flex flex-column justify-content-center">
															<h5 class="mb-1">{{group.name}}</h5>
															<p class="mb-1">{{getCurrentGroupAchievement(group.id).description}}</p>
														</div>
														<div class="col-4 d-flex flex-column justify-content-center">
															<div class="text-end me-4" v-if="hasDoneGroup(group.id)">
																<span class="text-success">
																	<i class="tkIcon tkIcon-ico_check me-2"></i>
																	<span>Завершено!</span>
																</span>
															</div>
															<div class="text-end me-4" v-else>
																<small class="text-muted">Награда</small>
																<div>
																	<span>{{getCurrentGroupAchievement(group.id).award}}</span>
																	<i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i>
																</div>
																<div class="progress">
																	<div class="progress-bar bg-warning" role="progressbar" aria-label="Progress" :style="{width: getPercent(group.currentValue, getCurrentGroupAchievement(group.id).value)+'%'}" :aria-valuenow="getPercent(group.currentValue, getCurrentGroupAchievement(group.id).value)" aria-valuemin="0" aria-valuemax="100">{{group.currentValue}} / {{getCurrentGroupAchievement(group.id).value}}</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</button>
										</div>
										<div :id="'flush-collapse'+group.id" class="accordion-collapse collapse" :aria-labelledby="'flush-heading'+group.id" :data-bs-parent="'#accordionFlush'+group.id">
											<div class="accordion-body">
												<div class="text-muted mb-1">ЗАВЕРШЕННЫЕ УРОВНИ:</div>
												<table class="table table-borderless align-middle mb-0 achievement-levels">
													<tr v-for="ach in getNoDoneAchievements(group.id)" :key="ach.id">
														<td width="80">
															<div class="icon-column">
																<img :src="'https://iwstatic.g.bsrv.su/img/achievements/frames/frame'+ucFirst(ach.level)+'.png'" class="achievement-flag" />
																<img :src="'https://iwstatic.g.bsrv.su/img/achievements/icons/'+group.icon" class="achievement-icon" style="" v-if="group.icon" />
															</div>
														</td>
														<td>{{ach.description}}</td>
														<td width="100" class="text-end">
															<small class="text-muted">Награда</small>
															<div class="pt-0">
																<span>{{ach.award}}</span>
																<i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i>
															</div>
														</td>
														<td width="100" class="text-end">
															<small class="text-muted">Получено</small>
															<div class="pt-0">{{group.done[ach.id]||''}}</div>
														</td>
													</tr>
												</table>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-6 text-start">
										<strong>Завершено: {{achievementsDoneCount}} / {{achievementsCount}}</strong>
									</div>
									<div class="col-6 text-end">
										<strong>Общий престиж {{prestige}} <i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i></strong>
									</div>
								</div>
							</b-overlay>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
.icon-column {
    position: relative;
    width: 80px;
    height: 80px;
    padding: 0;
}
.achievement-levels .icon-column {
    position: relative;
    width: 48px;
    height: 48px;
	padding: 0;
}
.icon-column .achievement-flag {
	width: 80px;
	height: 80px;
}
.icon-column .achievement-icon {
    position: absolute;
    top: 15px;
    left: 15px;
	width: 50px;
	height: 50px;
}
.achievement-levels .icon-column .achievement-flag {
	width: 48px;
	height: 48px;
}
.achievement-levels .icon-column .achievement-icon {
    position: absolute;
    top: 9px;
    left: 9px;
	width: 30px;
	height: 30px;
}

.tkIcon.tkIcon-feature_prestige_small_flat_black,
.tkIcon.tkIcon-prestige_bronzeBadge_small_illu {
    background-image: url(https://lobby.kingdoms.com/static/media/general-rtl.fb6cf822.png);
    background-image: url(https://lobby.kingdoms.com/static/media/general-ltr.88c67910.png);
    display: inline-block;
    background-repeat: no-repeat;
    height: 16px;
}
.tkIcon.tkIcon-ico_birthday_server_black_small,
.tkIcon.tkIcon-ico_check {
    background-image: url(https://lobby.kingdoms.com/static/media/general-rtl.fb6cf822.png);
    background-image: url(https://lobby.kingdoms.com/static/media/general-ltr.88c67910.png);
    display: inline-block;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
}
.tkIcon.tkIcon-feature_prestige_small_flat_black {
    width: 16px;
    background-position: 0 -945px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-ico_check {
    background-position: 0 -401px /*!rtl:end:ignore*/;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import BOverlay from '@/components/bootstrap/overlay.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Achievements | IW'});
	},
	data: () => ({
		achievementsOverlay: true,
		
		prestige: 170,
		groups: [
			/*{
				id: 1,
				name: 'Тише едешь - дальше будешь',
				icon: 'icon_43.png',
				currentValue: 93,
				achievements: [
					{
						id: 1,
						frame: 'frame1.png',
						description: 'Завершить не менее 5 заданий.',
						award: 1,
						value: 5,
					},
					{
						id: 2,
						frame: 'frame2.png',
						description: 'Завершить не менее 25 заданий.',
						award: 2,
						value: 25,
					},
					{
						id: 3,
						frame: 'frame3.png',
						description: 'Завершить не менее 100 заданий.',
						award: 3,
						value: 100,
					},
				],
				done: {
					1: '10.07.2020',
					2: '12.07.2020',
				},
			},
			{
				id: 2,
				name: 'Грабим богатых',
				icon: 'icon_19.png',
				currentValue: 37000,
				achievements: [
					{
						id: 4,
						frame: 'frame1.png',
						description: 'Захватить не менее 2000 ресурсов в одной битве.',
						award: 2,
						value: 2000,
					},
					{
						id: 5,
						frame: 'frame2.png',
						description: 'Захватить не менее 10000 ресурсов в одной битве.',
						award: 4,
						value: 10000,
					},
					{
						id: 6,
						frame: 'frame3.png',
						description: 'Захватить не менее 50000 ресурсов в одной битве.',
						award: 6,
						value: 50000,
					},
					{
						id: 7,
						frame: 'frame4.png',
						description: 'Захватить не менее 200000 ресурсов в одной битве.',
						award: 8,
						value: 200000,
					},
				],
				done: {
					4: '10.07.2020',
					5: '12.07.2020',
				},
			},
			{
				id: 3,
				name: 'Рыбалка',
				icon: 'icon_16.png',
				currentValue: null,
				achievements: [
					{
						id: 8,
						frame: 'frameMystery.png',
						description: 'Моя любимая деятельность в многопользовательских играх!',
						award: 10,
						value: null,
					},
				],
				done: {
					8: '10.07.2020',
				},
			},*/
		],
    }),
	computed: {
		achievementsCount(){
			let count = 0;
			for(let g of this.groups){
				count += g.achievements.length;
			}
			return count;
		},
		achievementsDoneCount(){
			let count = 0;
			for(let g of this.groups){
				count += Object.entries(g.done).length;
			}
			return count;
		},
		
	},
	methods: {
		async getAchievements(){
			this.achievementsOverlay = true;
			await fetch('/api/user/achievements', {
				mode: 'cors',
				credentials: 'include',
				headers: {
					Authorization: 'Bearer '+storeInstance.state.app.auth.token,
				},
			}).then(stream => stream.json()).then(data => {
				this.groups = data.achievements;
				setTimeout(() => {
					this.achievementsOverlay = false;
				}, 1000);
			}).catch(error => {
				console.error(error);
			});
		},
		
		getNoDoneAchievements(group_id){
			let group = this.groups.find((elem) => elem.id == group_id);
			if(group){
				return group.achievements.filter((elem) => typeof(group.done[elem.id]) !== 'undefined');
			}
			return [];
		},
		getCurrentGroupAchievement(group_id){
			let result = {};
			let group = this.groups.find((elem) => elem.id == group_id);
			if(group){
				for(let ach of group.achievements){
					result = ach;
					if(typeof(group.done[ach.id]) === 'undefined') break;
				}
			}
			return result;
		},
		hasDoneGroup(group_id){
			let group = this.groups.find((elem) => elem.id == group_id);
			if(group){
				return group.achievements.length == Object.entries(group.done).length;
			}
			return false;
		},
		getPercent(currentVal, maxVal){
			let ret = Math.round((currentVal / maxVal) * 100);
			return ret < 0 ? 0 : (ret > 100 ? 100 : ret);
		},
		ucFirst(str){
			if (!str) return str;
			return str[0].toUpperCase() + str.slice(1);
		},
	},
	async beforeMount(){
		await this.getAchievements();
		
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
	components: {
		BOverlay,
	},
}
</script>