<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Почта</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/images/post1.jpg" width="150" height="106" /> <img src="//iwstatic.g.bsrv.su/resources/images/post5.jpg" width="150" height="106" />
		</p>
		<p><b>Почта</b> - общение в чате зависит от этого строения. Чем выше уровень строения, тем большее кол-во комнат может посетить пользователь. Также этот почта позволяет зарабатывать деньги, привлекая новых игроков в игру. Максимальное количество уровней - 10.</p>
		<p><b>Особенности строения:</b></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisMail',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>