<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Великая осада</h3>
		</header>
		
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/the_great_siege.png" />
		</p>
		<h5>Описание</h5>
		<p>Великая осада - это сценарий «Короля горы». В середине мира есть большой город, который состоит из нескольких деревень, которые игрок может захватить. Эти деревни называются районами и дают племени контролирующего игрока определенное количество очков влияния. Чтобы завоевать мир, племени нужно будет достичь определенного количества очков.</p>
		
		<h5>Старт мира</h5>
		<p>Сам мир начинается как и любой другой мир, единственное отличие - это город в центре карты. Игрок может начинать и выполнять свои обычные дела - фармить, атаковать других игроков, нанимать войска, модернизировать здания и т. д. Но чтобы завоевать мир, племена должны атаковать и удерживать деревни, составляющие центральный город. Вначале в городе будут варварские подразделения, которые игроки должны сначала победить (это обычные подразделения, в них нет ничего особенного).</p>
		
		<h5>Кварталы</h5>
		<p>В каждом мире c этим сценарием завершения вы найдете огромный город в центре карты. Город может варьироваться по размеру и состоит из нескольких кварталов. Эти кварталы работают как обычные деревни, за исключением того, что их нельзя переименовать, у них всегда доверие населения 1, и путешествие к ним всегда занимает одинаковое количество времени. Даже из квартала в квартал. Кроме того, у кварталов отрицательный модификатор защиты, что еще более затрудняет их защиту. Значение эффекта варьируется между кварталами, но кварталы, которые труднее защищать, также дадут больше очков влияния, которые необходимы для завоевания мира.</p>
		
		<h5>Районы</h5>
		<p>Очки влияния даются племенам за каждый квартал, который они удерживают, но в зависимости от того района, к которому они принадлежат. Хотя количество кварталов может варьироваться между мирами, город всегда будет состоять из четырех районов. Каждый район награждает очками влияния в разное время суток. Вы можете это увидеть в таблице:</p>
		<table class="table table-bordered text-center"> 
			<tbody>
				<tr>
					<td>Городская площадь</td>
					<td>10:00</td>
				</tr>
				<tr>
					<td>Финансовый район</td>
					<td>14:00</td>
				</tr>
				<tr>
					<td>Ремесленный район</td>
					<td>18:00</td>
				</tr>
				<tr>
					<td>Гавань</td>
					<td>22:00</td>
				</tr>
			</tbody>
		</table>
		<p>Чтобы получить очки влияния, вашему племени нужно будет удерживать кварталы в это точное время. Это единственный способ получить очки влияния, поэтому вам нужно убедиться, что кварталы находятся под вашим контролем, когда таймер города достигает нуля. Поэтому убедитесь, что ваша оборона прочная, или не забудьте бросить все ваши войска против квартала, чтобы попытаться взять его на последней секунде.</p>
		<p>Как только племя достигнет порога 40% требуемых очков влияния, затраты на золотые монеты будут автоматически уменьшены на 10% для всех в мире. Это продолжается с каждыми дополнительными 10%, пока не будет достигнуто 90% требуемых очков влияния, а золотые монеты сократятся до 40% от их первоначальной стоимости.</p>
		
		<h5>Интерфейс</h5>
		<table class="table table-bordered text-center"> 
			<tbody>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_map_ru.png" />
					</td>
					<td>
						<p>Центральный город всегда находится в центре карты.</p>
						<p>Он может быть разного размера, это зависит от настроек игрового мира.</p>
						<p>При наведении мышки игрок получит самую важную информацию о конкретном квартале:</p>
						<p>Модификатор защиты Сколько очков влияния присуждается в день (за удержание квартала)</p>
						<p>И кто контролирует квартал</p>
					</td>
				</tr>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_ranking_ru.png" />
					</td>
					<td>В обзоре рейтинга игрок найдет подробный обзор по отдельным кварталам, а также четырем районам. Каждый район награждает очками влияния в разное время суток. Чтобы получить очки победы, игрок племени должен контролировать деревню, когда обратный отсчет достигает нуля.</td>
				</tr>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_ranking2_ru.png" />
					</td>
					<td>Игрок может включить отображение модификатора защиты прямо в обзоре.</td>
				</tr>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_village_ru.png" />
					</td>
					<td>
						<p>Отрицательный модификатор защиты обозначен цветным разломанным щитом.</p>
						<p>Игрок <b>не может</b> изменить название района!</p>
						<p>Но игрок '<i>может</i> строить здания и нанимать войска</p>
					</td>
				</tr>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_village2.png" />
					</td>
					<td>
						<p>Модификатор защиты также отображается в списке эффектов</p>
						<p>Также, учтите, что доверие населения всегда 1</p>
						<p>Во всём остальном деревня ведёт себя как обычна деервня</p>
					</td>
				</tr>
				<tr>
					<td>
						<img src="//iwstatic.g.bsrv.su/img/wiki/gs_influence_ru.png" />
					</td>
					<td>Племя выигрывает мир, когда оно получает необходимое количество очков влияния. Отчет отправляется всем игрокам, когда у племени 80% требуемых очков</td>
				</tr>
			</tbody>
		</table>
		
		<h5>Автоматическое уменьшение очков влияния для победы</h5>
		<p>Со временем количество очков победы необходимых для победы будет уменьшаться. Они будут уменьшаться до того момента, пока племя топ-1 не будет иметь 85% от необходимых для победы очков.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>