<template>
	<div class="mt-5">
		<div class="container">
			<div class="row mb-5">
				<div class="col-md-12 text-center">
					<div class="card h-100">
						<div class="card-header">
							<h3>Профиль</h3>
						</div>
						<div class="card-body">
							<div class="row g-4">
								<div class="col-12 col-md-6 col-lg-4">
									<div class="card h-100">
										<div class="card-header">Информация об аккаунте</div>
										<div class="card-body">
											<p>
												<strong>ID пользователя:</strong>
												{{authModel.userProfile.user_id}}
											</p>
											<p>
												<strong>Email:</strong>
												{{authModel.userProfile.email}}
											</p>
											<p>
												<strong>Пароль:</strong>
												***********
											</p>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6 col-lg-4">
									<div class="card h-100">
										<div class="card-body text-center">
											<img
												id="profile-img"
												src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
												class="profile-img-card"
											/>
										</div>
									</div>
								</div>
								<div class="col-12 col-md-6 col-lg-4">
									<div class="card h-100">
										<div class="card-header">Общая информация</div>
										<div class="card-body">
											<p>
												<strong>Регистрация:</strong>
												10.07.2020
											</p>
											<p>
												<strong>Подписки:</strong>
												Нет
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row mb-5">
				<div class="col-md-12 text-center">
					<div class="card h-100">
						<div class="card-header">
							<h3>Достижения и престиж</h3>
						</div>
						<div class="card-body">
							<div class="row">
								<div class="col-sm-3">
									<div class="card text-bg-primary h-100">
										<div class="card-header">
											Общий престиж
										</div>
										<div class="card-body">
											<i class="tkIcon tkIcon-prestige_cup_big_illu prestige-cup"><span>245</span></i>
										</div>
										<div class="card-footer">
											<div class="row">
												<div class="col-4 text-start">4</div>
												<div class="col-4 text-center">Ранг</div>
												<div class="col-4 text-end">5</div>
											</div>
											<div class="progress">
												<div class="progress-bar bg-warning" role="progressbar" aria-label="Example with label" style="width: 25%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
											</div>
											<small class="text-center">Следующий ранг: 300 очков</small>
										</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="card h-100">
										<div class="card-header">
											Достижения
										</div>
										<div class="card-body">
											<div class="achievements tkIcon tkIcon-prestigeUI_achievement">
												<i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i>
												<span>117</span>
											</div>
										</div>
										<div class="card-footer">
											<router-link :to="{path: '/achievements'}" class="btn btn-primary">Подробнее</router-link>
										</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="card h-100">
										<div class="card-header">
											Завершенные миры
										</div>
										<div class="card-body">
											<div class="finished-worlds tkIcon tkIcon-prestigeUI_finished_gameworld">
												<i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i>
												<span>128</span>
											</div>
										</div>
										<div class="card-footer">
											<router-link :to="{path: '/hof'}" class="btn btn-primary">Подробнее</router-link>
										</div>
									</div>
								</div>
								<div class="col-sm-3">
									<div class="card text-bg-light h-100">
										<div class="card-header">
											Активные миры
										</div>
										<div class="card-body">
											<div class="active-worlds tkIcon tkIcon-prestigeUI_active_gameworld">
												<i class="tkIcon tkIcon-feature_prestige_small_flat_black"></i>
												<span>0</span>
											</div>
										</div>
										<div class="card-footer">
											<a href="#" class="btn btn-light disabled">Нет активных миров</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">
.achievements,
.active-worlds,
.finished-worlds {
    margin: 0 auto 14px;
    padding-top: 30px;
}
.prestige-cup {
    width: 114px;
    height: 89px;
    margin: 0 auto;
    text-align: center;
    color: #fff;
    padding-top: 41px;
    font-style: normal;
}
.achievements .tkIcon,
.active-worlds .tkIcon,
.finished-worlds .tkIcon {
    display: inline-block;
    vertical-align: middle;
    margin: 0 3px 0 auto;
}
.tkIcon.tkIcon-prestige_cup_big_illu {
    width: 114px;
    height: 89px;
    background-position: 0 -3204px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-prestigeUI_achievement {
    width: 80px;
    height: 80px;
    background-position: 0 -3124px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-prestigeUI_finished_gameworld {
    background-position: 0 -2964px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-feature_prestige_small_flat_black {
    width: 16px;
    background-position: 0 -945px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-prestigeUI_active_gameworld {
    background-position: 0 -3044px /*!rtl:end:ignore*/;
}
.tkIcon.tkIcon-prestige_cup_big_illu,
.tkIcon.tkIcon-prestigeUI_achievement {
    background-image: url(https://lobby.kingdoms.com/static/media/general-rtl.fb6cf822.png);
    background-image: url(https://lobby.kingdoms.com/static/media/general-ltr.88c67910.png);
    display: inline-block;
    background-repeat: no-repeat;
}
.tkIcon.tkIcon-prestigeUI_active_gameworld,
.tkIcon.tkIcon-prestigeUI_finished_gameworld {
    background-image: url(https://lobby.kingdoms.com/static/media/general-rtl.fb6cf822.png);
    background-image: url(https://lobby.kingdoms.com/static/media/general-ltr.88c67910.png);
    display: inline-block;
    background-repeat: no-repeat;
    width: 80px;
    height: 80px;
}
.tkIcon.tkIcon-feature_prestige_small_flat_black,
.tkIcon.tkIcon-prestige_bronzeBadge_small_illu {
    background-image: url(https://lobby.kingdoms.com/static/media/general-rtl.fb6cf822.png);
    background-image: url(https://lobby.kingdoms.com/static/media/general-ltr.88c67910.png);
    display: inline-block;
    background-repeat: no-repeat;
    height: 16px;
}
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Profile | IW'});
	},
	computed: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>