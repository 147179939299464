<template>
	<transition name="b-overlay-fade" :css="!noFade">
		<component
			:is="overlayTag"
			class="b-overlay"
			:class="['position-'+(fixed ? 'fixed' : 'absolute')]"
			style="inset: 0px;"
			:style="{zIndex}"
		>
			<div
				class="position-absolute"
				:class="[roundedVal, variantVal]"
				style="inset: 0px;"
				:style="{opacity, backdropFilter}"
			></div>
			<div
				class="position-absolute"
				:style="{inset: (noCenter ? '0px' : null), top: (!noCenter ? '50%' : '0px'), left: (!noCenter ? '50%' : '0px'), transform: (!noCenter ? 'translateX(-50%) translateY(-50%)' : null)}"
			>
				<slot>
					<b-spinner
						:type="spinnerType"
						:small="spinnerSmall"
						:variant="spinnerVariant"
					></b-spinner>
				</slot>
			</div>
		</component>
	</transition>
</template>

<style lang="scss" scoped>
.b-overlay-fade-enter-active,
.b-overlay-fade-leave-active {
	transition: opacity .3s;
}
.b-overlay-fade-enter,
.b-overlay-fade-leave-to {
	opacity: 0;
}
</style>

<script>
import BSpinner from '@/components/bootstrap/spinner.vue';

export default {
	name: 'BOverlayInner',
	props: {
		// Цвет CSS для использования в качестве цвета фона непрозрачного наложения. Если установлено, переопределяет реквизит `variant`
		bgColor: {
			type: String,
		},
		// Значение фонового фильтра размытия CSS. Не забудьте включить единицы CSS. Не поддерживается в IE 11. Установите значение null или пустую строку, чтобы отключить размытие
		blur: {
			type: String,
			default: '2px',
		},
		// Когда установлено свойство `no-wrap`, будет использоваться фиксированное позиционирование вместо абсолютного позиционирования. Удобно, если вы хотите скрыть всю страницу приложения
		fixed: {
			type: Boolean,
			default: false,
		},
		// Если установлено, отключает вертикальное и горизонтальное центрирование содержимого наложения
		noCenter: {
			type: Boolean,
			default: false,
		},
		// Отключает плавный переход наложения
		noFade: {
			type: Boolean,
			default: false,
		},
		// Непрозрачность фона наложения. Допустимый диапазон: от «0» до «1»
		opacity: {
			type: [Number, String],
			default: 0.85,
		},
		// Тег элемента для использования в качестве элемента наложения
		overlayTag: {
			type: String,
			default: 'div',
		},
		// Примените округление к наложению, чтобы оно соответствовало маршрутизации вашего контента. Допустимые значения: «true», «sm», «lg», «circle», «pill», «top», «right», «bottom» или «left»
		rounded: {
			type: [Boolean, String],
			default: false,
		},
		// Если установлено, отображает счетчик по умолчанию в меньшем размере
		spinnerSmall: {
			type: Boolean,
			default: false,
		},
		// Тип счетчика по умолчанию для отображения. Текущие поддерживаемые типы: «граница» и «рост»
		spinnerType: {
			type: String,
			default: 'border',
		},
		// Применяет один из цветовых вариантов темы Bootstrap к счетчику по умолчанию. По умолчанию используется текущий цвет шрифта
		spinnerVariant: {
			type: String,
		},
		// Вариант цвета фоновой темы для использования в качестве фона наложения
		variant: {
			type: String,
			default: 'light',
		},
		// Значение Z-индекса для применения к наложению. Возможно, вам придется увеличить это значение в соответствии с вашим контентом или местом размещения
		zIndex: {
			type: [Number, String],
			default: 10,
		},
	},
	data(){
		return {
			
		};
	},
	computed: {
		roundedVal(){
			return this.rounded && this.rounded != 'false' ? 'rounded'+(this.rounded != 'true' ? '-'+this.rounded : '') : null;
		},
		variantVal(){
			return this.bgColor ? 'bg-'+this.bgColor : (this.variant ? 'bg-'+this.variant : null);
		},
		backdropFilter(){
			return this.blur ? 'blur('+this.blur+')' : null;
		},
	},
	methods: {},
	components: {
		BSpinner,
	},
}
</script>