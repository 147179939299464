<template>
	<div>
		<div class="card mb-3" v-if="post.poster">
			<div class="row g-0">
				<div class="col-md-4">
					<img :src="post.poster" class="img-fluid rounded-start" alt="poster" />
				</div>
				<div class="col-md-8">
					<div class="card-body">
						<h5 class="card-title">{{post.title}}</h5>
						<p class="card-text">{{post.cutcontent}}</p>
						<router-link class="card-link float-right stretched-link" :to="{path: post.url}" v-if="post.url">Подробнее</router-link>
						<p class="card-text"><small class="text-muted">{{post.datetime}}</small></p>
					</div>
				</div>
			</div>
		</div>
		<div class="card mb-3" v-else>
			<div class="card-body">
				<h5 class="card-title">{{post.title}}</h5>
				<p class="card-text">{{post.cutcontent}}</p>
				<router-link class="card-link float-right stretched-link" :to="{path: post.url}" v-if="post.url">Подробнее</router-link>
				<p class="card-text"><small class="text-muted">{{post.datetime}}</small></p>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
	name: 'NewsPost',
	props: ['post'],
	computed: {},
	methods: {},
	components: {},
}
</script>