<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Легенда</h3>
		</header>
		<p>В этом мире время Людей ушло. Пусть другие расы попробуют пройти Путь. <router-link to="/wiki/opis_elfs">Эльфы</router-link>, <router-link to="/wiki/opis_bastards">Бастарды</router-link>, <router-link to="/wiki/opis_valkiria">Валькирии</router-link>, <router-link to="/wiki/opis_gnoms">Гномы</router-link>, <router-link to="/wiki/opis_mert">Мертвые</router-link>, <router-link to="/wiki/opis_goblins">Гоблины</router-link>, <router-link to="/wiki/opis_trols">Тролли</router-link> - вот на кого пал выбор. Каждого из существ Те, кто правит миром, поселили на одном из пустынных островов, которые в безчисленном множестве раскиданы в океане. Острова - это все, что осталось от некогда огромных территорий суши после потопа. Эти клочки земли, небольшой первоначальный запас ресурсов и уникальные знания, которыми обладает каждая раса, - вот все, что есть для того, чтобы достичь успеха...</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiLegend',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>