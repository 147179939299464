<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Секреты могущества</h3>
		</header>
		
		<h5>Основы</h5>
		<p>Итак, вы построили войска, захватили врагов, присоединились к могущественному племени и наводите ужас на менее удачливых игроков, где бы они не находились. Что дальше? По прошествии определенного времени с начала старта, в варварках по всему миру появятся «Секреты Могущества». Эти секреты – изобретения и открытия, которые приведут ваш мир в новую эру. И они станут главными целями игры: Племя, сумевшее получить полный набор Секретов за определенное время станет доминантной силой в новой эре, и таким образом, победит в игре.</p>
		
		<h5>Контроль над Секретом</h5>
		<p>Игрок получает секрет, либо когда завоевывает деревню, где секрет находится в данный момент (на карте такие деревни будут помечены флажком) либо когда этот секрет перемещается к нему в деревню. В отличие от войск, Секрет может за один раз перемещаться только на небольшое расстояние в несколько клеток. После перемещения, Секрет какое-то время (настраивается в конфигурации мира) должен храниться в деревне, прежде чем он будет готов к следующему перемещению. Как только игрок отправляет от себя Секрет, он теряет над ним контроль. Перемещение нельзя отменить, нельзя вернуть себе отправленный секрет, если только вам его не вернет его текущий хранитель. Поэтому необходимо осторожно отнестись к выбору, кому доверить секрет.</p>
		
		<h5>Распространение Секретов</h5>
		<p>Со временем секреты, хранящиеся в племени, начинают воспроизводиться среди его игроков. Вы будете периодически получать сообщения, когда и возможно – где появится копия Секрета. Новоиспеченные копии Секретов будут ничуть не хуже оригиналов. Со временем Секреты воспроизведутся в таком количестве, что станет весьма сложно удержать их от попадания в жадные руки конкурентов. И в конечном счете придет пора атаковать чужие деревни, хранящие Секреты, пока еще недоступные вашему племени.</p>
		
		<h5>Победа в игре</h5>
		<p>Чтобы выиграть, племя должно захватить набор Секретов. Это значит, что вам не обязательно захватывать все секреты на карте, достаточно только захватить по 1 Секрету каждого типа. После того, как племя получает контроль над набором начинается отсчёт до победы. Если племя удержит контроль над захваченными секретами, оно выиграет игру. Учтите, что считается только набор секретов: если у племени более одной копии секрета, достаточно для этого племени удержать контроль над одной из копий, чтобы сохранить полный набор и выиграть игру.</p>
		<p>После того, как племя выиграет, в мире начнётся перемирие, а затем он будет закрыт.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>