const Bylex = {
	name: 'bylex',
	url: '/auth/bylex',
	authorizationEndpoint: 'https://id.bylex.su/login-out',
	redirectUri: window.location.origin,
	responseMode: ['form_post'],
	requiredUrlParams: ['state', 'scope'],
	scope: ['userinfo'],
	scopeDelimiter: ',',
	state: 'STATE',
	display: 'popup',
	oauthType: '2.0',
	popupOptions: {
		width: 460,
		height: 630,
	},
};
export { Bylex };
