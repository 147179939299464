import lib from '@/lib';

export default class NoticeModel
{
	STATUS_LOADING = 'loading';
	STATUS_LOADED = 'loading';
	STATUS_LOAD_ERROR = 'error';
	
	/**
	 * Конструктор
	 */
	constructor()
	{
		this.unreaded = [];
		this.unreaded_page = 0;
		this.unreaded_pages = 1;
		this.unreaded_load_status = '';
		this.readed = [];
		this.readed_page = 0;
		this.readed_pages = 1;
		this.readed_load_status = '';
	}
	
	/**
	 * Получить непрочитанные сообщения
	 */
	async loadUnreadedMessages()
	{
		this.unreaded_load_status = NoticeModel.STATUS_LOADING;
		
		let response = await lib.ioCallAction({
			entity: 'com.notice.model.notices.getUnreaded',
			params: {
				pagenum: this.unreaded_page,
			},
		});
		
		if(response && response.error_code == 1 && response.data != null){
			this.unreaded = response.data;
			this.unreaded_load_status = NoticeModel.STATUS_LOADED;
			this.unreaded_pages = response.pagescount;
		} else {
			//this.unreaded = [];
			this.unreaded_load_status = NoticeModel.STATUS_LOAD_ERROR;
		}
	}
	
	/**
	 * Получить прочитанные сообщения
	 */
	async loadReadedMessages()
	{
		this.readed_load_status = NoticeModel.STATUS_LOADING;
		
		let response = await lib.ioCallAction({
			entity: 'com.notice.model.notices.getReaded',
			params: {
				pagenum: this.readed_page,
			},
		});
		
		if(response && response.error_code == 1 && response.data != null){
			this.readed = response.data;
			this.readed_pages = response.pagescount;
			this.readed_load_status = NoticeModel.STATUS_LOADED;
		} else {
			//this.readed = [];
			this.readed_load_status = NoticeModel.STATUS_LOAD_ERROR;
		}
	}
	
	/**
	 * Найти сообщение по pkid
	 */
	async findMessageByPkid(pkid)
	{
		let res1 = this.unreaded.find(p => {
			return p.pkid == pkid;
		});
		let res2 = this.readed.find(p => {
			return p.pkid == pkid;
		});
		return res1 || res2 || null;
	}
	
	/**
	 * Отметить прочитанным сообщение
	 */
	async setMessageAsReaded(pkid)
	{
		let response = await lib.ioCallAction({
			entity: 'com.notice.model.notices.setReaded',
			params: {
				pkid 
			},
		});
		
		if(response && response.error_code == 1){
			let message = await this.findMessageByPkid(pkid)
			if(message != null){
				let index = this.unreaded.indexOf(message);
				if(index >= 0){
					this.unreaded.splice(index, 1);
					//this.readed.unshift(message);
				}
			}
		}
		
		return false;
	}
}
