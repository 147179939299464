<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Эпоха просвещения</h3>
		</header>
		
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/university.jpg" />
		</p>
		<h5>Описание</h5>
		<p>«Эпоха просвещения» - это сценарий победы в мире, который был представлен в июле 2019 года. Здесь игрокам или племенам необходимо завоевать одну из специальных университетских деревень и обновить университет до определенного уровня. Как только племя или игрок построят последний уровень Университета в этой специальной деревне, мир закроется.</p>
		<p>Чтобы быстрее достичь этой цели, игроки племени должны работать вместе, чтобы отправлять ресурсы в деревню. Идея этого сценария заключается в том, чтобы создать и модернизировать «Чудо света» как условие победы.</p>
		
		<h5>Захват университетской деревни</h5>
		<p>В начале мира университетские деревни появляются в предопределенных местах на карте и находятся под защитой от атак. Эта защита от атак будет действовать до начала заключительного этапа игры.</p>
		<p>Поскольку деревни доступны с самого начала мира, вам и вашему племени нужно будет спланировать как и когда вы хотите начать их захват до того, как защита атак будет снята. Обычная механика развития варварских деревень отключена для университетских деревень, поэтому очки деревни не будут изменятся в период, когда деревня находится под защитой от атак.</p>
		<p>Университетские деревни размещаются на следующих координатах:</p>
		<ul>
			<li>499 | 450</li>
			<li>550 | 499</li>
			<li>500 | 550</li>
			<li>450 | 500</li>
		</ul>
		<p>В больших мирах дополнительные деревни размещаются здесь:</p>
		<ul>
			<li>400 | 400</li>
			<li>400 | 599</li>
			<li>599 | 400</li>
			<li>599 | 599</li>
		</ul>
		
		<h5>Улучшение университета</h5>
		<img src="//iwstatic.g.bsrv.su/img/wiki/mapicon_03.png" style="margin: 0 0 0.5em 0.5em; float: right;" />
		<p>Через 75 дней защита от атак снимается, и университетские деревни можно завоевывать. Когда университетская деревня завоевана, владелец деревни может начать улучшение университета (первый уровень уже построен и не может быть разрушен). Другие племена могут атаковать и / или завоевывать университетские деревни, которые вы контролируете. В университетской деревне игроки могут только улучшать университет (и никакие другие здания). Флаги, влияющие на наём войск, не будут иметь никакого эффекта. Сокращение времени строительства за премиум, а также такие предметы, как Привилегия не могут быть использованы в университетской деревне.</p>
		<p>Чтобы улучшить университет, вам и вашему племени необходимо отправить ресурсы в деревню. Как только в университете будет необходимое количество ресурсов, вы можете улучшить его до следующего уровня, нажав кнопку «Улучшить». Поскольку университетская деревня может хранить в общей сложности 2 000 000 000 ресурсов, использование предмета «Набор ресурсов» не окажет влияния на университетские деревни.</p>
		<p>В следующей таблице вы можете увидеть время строительства, необходимое для каждого уровня университета:</p>
		<table class="table table-bordered text-center">
			<tbody>
				<tr>
					<th colspan="15"><b>Время строительства университета</b></th>
				</tr>
				<tr>
					<td><b>Уровень</b></td>
					<td><b>Время (в часах)</b></td>
				</tr>
				<tr>
					<td>1</td>
					<td>2</td>
				</tr>
				<tr>
					<td>2</td>
					<td>4</td>
				</tr>
				<tr>
					<td>3</td>
					<td>6</td>
				</tr>
				<tr>
					<td>4</td>
					<td>8</td>
				</tr>
				<tr>
					<td>5</td>
					<td>10</td>
				</tr>
				<tr>
					<td>6</td>
					<td>12</td>
				</tr>
				<tr>
					<td>7</td>
					<td>14</td>
				</tr>
				<tr>
					<td>8</td>
					<td>16</td>
				</tr>
				<tr>
					<td>9</td>
					<td>18</td>
				</tr>
				<tr>
					<td>10</td>
					<td>20</td>
				</tr>
				<tr>
					<td>11</td>
					<td>22</td>
				</tr>
				<tr>
					<td>12</td>
					<td>24</td>
				</tr>
				<tr>
					<td>13</td>
					<td>26</td>
				</tr>
				<tr>
					<td>14</td>
					<td>28</td>
				</tr>
				<tr>
					<td>15</td>
					<td>30</td>
				</tr>
				<tr>
					<td>16</td>
					<td>32</td>
				</tr>
				<tr>
					<td>17</td>
					<td>34</td>
				</tr>
				<tr>
					<td>18</td>
					<td>36</td>
				</tr>
				<tr>
					<td>19</td>
					<td>38</td>
				</tr>
				<tr>
					<td>20</td>
					<td>40</td>
				</tr>
				<tr>
					<td>21</td>
					<td>42</td>
				</tr>
				<tr>
					<td>22</td>
					<td>44</td>
				</tr>
				<tr>
					<td>23</td>
					<td>46</td>
				</tr>
				<tr>
					<td>24</td>
					<td>48</td>
				</tr>
				<tr>
					<td>25</td>
					<td>52</td>
				</tr>
				<tr>
					<td>26</td>
					<td>56</td>
				</tr>
				<tr>
					<td>27</td>
					<td>64</td>
				</tr>
				<tr>
					<td>28</td>
					<td>72</td>
				</tr>
				<tr>
					<td>29</td>
					<td>80</td>
				</tr>
				<tr>
					<td>30</td>
					<td>96</td>
				</tr>
			</tbody>
		</table>
		
		<h5>Нападение на университетскую деревню</h5>
		<p>Противоборствующие племена и игроки могут атаковать любые университетские деревни на карте. Атаки на непокоренные университетские деревни должны быть хорошо спланированы, поскольку в каждой университетской деревне по умолчанию установлены следующие оборонительные подразделения:</p>
		<ul>
			<li>1.000 копейщиков</li>
			<li>1.000 мечников</li>
			<li>500 тяжёлой кавалерии</li>
		</ul>
		<p>Естественно, в контролируемые игроком университетские деревни племя сможет тоже отправлять подкрепления, поэтому вам следует ожидать максимального сопротивления! Имейте в виду (в зависимости от настроек сервера) другие игроки могут также отправлять больше войск для защиты непобедимой варварской деревни!</p>
		<p>Основная цель нападения на противоборствующие университетские деревни - понизить уровень университета, чтобы ваше племя достигло необходимого максимального уровня университета раньше всех. Существуют определенные действия, которые могут снизить уровень университета, такие как:</p>
		<ul>
			<li>Успешные атаки с катапультами</li>
			<li>Если университетская деревня считается завоеванной (уровень университета снижается на 2)</li>
			<li>Исключение игрока, который владеет университетской деревней из племени (уровень университета снижается на 2)</li>
		</ul>
		<p>В любом случае уровень университета не может упасть ниже 1. Кроме того, площадь, крестьянский двор и склад, принадлежащие университетской деревне, не могут быть атакованы катапультами.</p>
		
		<h5>Экран завершающей стадии игры</h5>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/aoe_ranking.png" />
		</p>
		<p>Игроки могут получить доступ к экрану завершающей стадии игры, щелкнув меню «Рейтинг», а затем выбрав «Эпоха Просвещения». На экране завершающей стадии игры игроки могут видеть:</p>
		<ul>
			<li>Университет</li>
			<li>Лидирующее племя / игрок</li>
			<li>Требуемый уровень университета, чтобы завоевать мир</li>
			<li>Список университетских сел, по возрастанию</li>
			<li>Текущее владение племенем каждой университетской деревни. Если деревня еще не принадлежит, ячейка племени / игрока будет пустой</li>
			<li>Координаты университетского поселка. Если вы нажмете на них, вы будете перенаправлены на местоположение деревни на карте</li>
			<li>Текущий уровень университета в каждой университетской деревне</li>
		</ul>
		
		<h5>Победа в мире</h5>
		<p>Если вам или вашему племени удастся построить последний уровень университета, мир закончится (когда строительство будет завершено), и начнётся перемирие. В течение этого периода игроки не могут покинуть (или быть исключены) своё племя, и победившее племя не может быть расформировано.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>