<template>
	<div class="container mt-5">
		<div class="row align-items-center">
			<div class="col-md-4 offset-md-4">
				<div class="card h-100">
					<div class="card-body text-center">
						<img
							id="profile-img"
							src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
							class="profile-img-card"
						/>
						<p class="card-text">admin panel</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Admin | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>