<template>
	<component :is="tag"
		aria-hidden="true"
		:class="['spinner-'+type, 'spinner-'+type+(small ? '-sm' : ''), {variantVal}]"
		:role="role"
	>
		<span
			class="sr-only"
			v-html="label"
			v-if="label"
		></span>
	</component>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
	name: 'BSpinner',
	props: {
		// Текстовое содержимое для размещения на label только для sr-only
		label: {
			type: String,
		},
		// Устанавливает для атрибута ARIA «роль» определенное значение
		role: {
			type: String,
			default: 'status',
		},
		// Если установлено, меньший счетчик отображается для размещения на кнопках
		small: {
			type: Boolean,
			default: false,
		},
		// Тег HTML для отображения вместо тега по умолчанию
		tag: {
			type: String,
			default: 'span',
		},
		// Тип счетчика для отображения. Текущие поддерживаемые типы: «border» и «grow»
		type: {
			type: String,
			default: 'border',
		},
		// Применяет к компоненту один из цветовых вариантов темы Bootstrap
		variant: {
			type: String,
		},
	},
	data(){
		return {
			
		};
	},
	computed: {
		variantVal(){
			return this.variant ? 'text-'+this.variant : null;
		},
	},
	methods: {},
	components: {},
}
</script>