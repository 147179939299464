<template>
	<div class="mt-5">
		<div class="container">
			<div class="row mb-5">
				<div class="col-md-12">
					<div class="card h-100">
						<div class="card-header text-center">
							<h3>Зал славы</h3>
						</div>
						<div class="card-body">
							hall of fame
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Hall of Fame | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
	methods: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>