<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Призраки</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/provodnik.jpg" width="274" height="410" title="Призрак" />
		</p>
		<p>Дабы всегда соблюдался установленный Теми, кто правит миром, порядок, был создан <b>Орден Призраков</b>. Никому из существ мира не дано право видеть призрака. Но каждый из призраков может видеть любого из существ, где бы он ни был и чтобы он не делал… Говорят, <b>Орден</b> настолько силен, что может следить даже за Богами, а <b>Командор Ордена</b> один сильнее всех Богов благодаря артефактам, которые он получил от Тех, кто правит миром...</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisGhost',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>