<template>
	<ol class="breadcrumb">
		<slot>
			<b-breadcrumb-item
				v-for="item in items"
				:active="item.active"
				:activeClass="item.activeClass"
				:append="item.append"
				:ariaCurrent="item.ariaCurrent"
				:disabled="item.disabled"
				:exact="item.exact"
				:exactActiveClass="item.exactActiveClass"
				:exactPath="item.exactPath"
				:exactPathActiveClass="item.exactPathActiveClass"
				:href="item.href"
				:rel="item.rel"
				:replace="item.replace"
				:target="item.target"
				:to="item.to"
				@click="item.click"
			></b-breadcrumb-item>
		</slot>
	</ol>
</template>

<style lang="scss" scoped>

</style>

<script>
import BBreadcrumbItem from '@/components/bootstrap/breadcrumbItem.vue';

export default {
	name: 'BBreadcrumb',
	props: {
		// Массив элементов breadcrumb для рендеринга
		items: {
			type: Array,
		},
	},
	data(){
		return {
			
		};
	},
	computed: {},
	methods: {},
	components: {
		BBreadcrumbItem,
	},
}
</script>