<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Экономическая составляющая игры / Игровая валюта</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/monetagreen.jpg" width="150" height="150" />
		</p>
		<p><b>Лан</b> - денежная единица, которая позволяет совершать обменные операции между игроками. Также с помощью этой валюты можно совершать покупку различных игровых бонусов.</p>
		<p>Получить ланы можно:
			<ul>
				<li>путем конвертации голдланов;</li>
				<li>в особо значимых боях (см. <router-link to="/wiki/opis_wars5">Дроп</router-link>);</li>
			</ul>
		</p>
		<p>10 ланов (ln) = 1 голдлану (gln) = 1 дол. США.</p>
		<p>Выводить ланы из игры нельзя.</p>
		<p>&nbsp;</p>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/monetagold.jpg" width="150" height="150" />
		</p>
		<p><b>Голдлан</b> - самая ценная игровая валюта. Она позволяет покупать специальные игровые возможности: раритетные вещи, артефакты, магические свитки, уникальные расы и аватары. Особенность ее  в том, что она может быть выведена из игры.</p>
		<p>1 голдлан (gln) = 10 ланам (ln) = 1 дол. США.</p>
		<p>К примеру, Вы приобрели 10 голдланов. 2 из них Вы обменяли на ланы, у Вас: 8 голдланов и 20 ланов. 8 голдланов Вы в любой момент можете вывести из игры. Для этого нужно посетить учреждение <router-link to="/wiki/opis_bank">Банка</router-link> и оформить заявление у клерка. В течении суток на кошелек веб-мани (web-money), который Вы указали, будет выслана заказанная сумма за вычетом 12% (комиссия web-money + отчисление в пользу игры).</p>
		<p>Голдланы можно приобрести только у <router-link to="/wiki/dilers">Дилеров</router-link>.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisEconom1',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>