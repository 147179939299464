<template>
	<vue-final-modal v-slot="{ params, close }" v-bind="$attrs" classes="modal fade show d-block" content-class="modal-dialog modal-dialog-centered">
		<div class="modal-content">
			<div class="modal-header">
				<h1 class="modal-title fs-5">
					<slot name="title"></slot>
				</h1>
				<button type="button" class="btn-close" @click="close"></button>
			</div>
			<div class="modal-body">
				<slot :params="params"></slot>
			</div>
			<div class="modal-footer">
				<button type="button" class="btn" :class="[btn.classes, {'btn-primary': !btn.classes}]" @click="$emit(btn.on, close)" v-for="btn in params.buttons">{{btn.text}}</button>
			</div>
		</div>
	</vue-final-modal>
</template>

<style lang="scss" scoped>

</style>

<script>
export default {
	inheritAttrs: false,
	//emits: ['confirm', 'cancel']
}
</script>