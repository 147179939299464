<template>
	<div class="mt-5">
		<div class="container">
			<div class="row mb-5">
				<div class="col-md-12 text-center">
					<b-overlay :show="serversOverlay" rounded="lg" variant="white">
						<div class="card h-100">
							<div class="card-header">
								<h3>Игровые миры</h3>
							</div>
							<div class="card-body">
								<div class="row g-4">
									<div class="col-12 col-md-6 col-lg-4" v-for="(server, pos) in servers">
										<b-overlay :show="server.overlay" rounded="lg" variant="light">
											<div class="card">
												<div class="card-body">
													<h2 class="card-title">{{server.name}}<br/>({{server.api_name.toUpperCase()}})</h2>
													<p class="card-text">{{server.name}} ({{server.lang.toUpperCase()}})</p>
												</div>
												<div class="card-footer">
													<button @click="onShowInfo(pos)" type="button" class="btn btn-secondary me-2">Инфо</button>
													<a :href="'https://'+server.domain" class="btn btn-primary" role="button">Играть</a>
												</div>
											</div>
										</b-overlay>
									</div>
								</div>
							</div>
						</div>
					</b-overlay>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>

</style>

<script>
import BOverlay from '@/components/bootstrap/overlay.vue';
import VDynamicModal from '@/components/VDynamicModal.vue';

export default {
	data: () => ({
		serversOverlay: false,
		servers: [],
		serversCount: 0,
    }),
	computed: {},
	methods: {
		onShowInfo(pos){
			this.servers[pos].overlay = true;
			
			fetch('/api/servers/'+this.servers[pos].id+'?' + new URLSearchParams({
				//limit: 1,
			})).then(stream => stream.json()).then(data => {
				this.$vfm.show({
					component: VDynamicModal,
					on: {
						start(close){
							location.href = 'https://'+data.domain;
						},
						cancel(close){
							close();
						},
					},
					slots: {
						title: 'Информация о мире',
						default: (
							'<div><b>Название:</b> '+data.name+' ('+data.api_name.toUpperCase()+')</div>'+
							'<div><b>Язык:</b> '+data.lang.toUpperCase()+'</div>'+
							'<div><b>Домен:</b> https://'+data.domain+'</div>'
						),
					},
					params: {
						buttons: [
							{on: 'start', text: 'Играть', classes: 'btn-primary'},
							{on: 'cancel', text: 'Закрыть', classes: 'btn-secondary'},
						],
					},
				});
				
				this.servers[pos].overlay = false;
			}).catch(error => {
				this.servers[pos].overlay = false;
				console.error(error);
			});
		},
	},
	beforeMount(){
		this.serversOverlay = true;
		fetch('/api/servers?' + new URLSearchParams({
			//limit: 1,
		})).then(stream => stream.json()).then(data => {
			this.serversCount = data.count;
			for(let i = 0; i < data.servers.length; i++){
				data.servers[i].overlay = false;
			}
			this.servers = data.servers;
			setTimeout(() => {
				this.serversOverlay = false;
			}, 1000);
		}).catch(error => {
			this.serversOverlay = false;
			console.error(error);
		});
	},
	components: {
		BOverlay,
	},
}
</script>