<template>
	<li
		class="breadcrumb-item"
		:class="{'active': active}"
	>
		<component
			:is="!!to || !!href ? 'b-link' : 'span'"
			:aria-current="ariaCurrent"
			:target="!!href ? target : null"
			:to="to"
			:append="!!to ? append : null"
			:replace="!!to ? replace : null"
			:exact="!!to ? exact : null"
			:exact-active-class="!!to ? exactActiveClass : null"
			:exact-path="!!to ? exactPath : null"
			:exact-path-active-class="!!to ? exactPathActiveClass : null"
			:event="!!to ? event : null"
			:rel="rel"
			:aria-disabled="!!disabled || undefined"
			:tabindex="!!disabled ? -1 : undefined"
			:class="{'disabled': !!disabled, activeClass: !!to}"
			:active-class="!!to ? activeClass : null"
			:disabled="disabled"
			:href="href"
			@click="click"
		>
			<slot></slot>
		</component>
	</li>
</template>

<style lang="scss" scoped>

</style>

<script>
import BLink from '@/components/bootstrap/link.vue';

export default {
	name: 'BBreadcrumbItem',
	props: {
		// Если установлено значение «true», помещает компонент в активное состояние с активным стилем
		active: {
			type: Boolean,
			default: false,
		},
		// Свойство <router-link>: настройте активный класс CSS, применяемый, когда ссылка активна. Обычно вы хотите установить это имя класса «активный»
		activeClass: {
			type: String,
		},
		// Свойство <router-link>: установка свойства append всегда добавляет относительный путь к текущему пути
		append: {
			type: Boolean,
			default: false,
		},
		// Устанавливает значение атрибута aria-current (когда элемент является активным элементом). Поддерживаемые строковые значения: «местоположение», «страница» или «истина».
		ariaCurrent: {
			type: String,
			default: 'location',
		},
		// Если установлено значение «true», отключает функциональность компонента и переводит его в отключенное состояние
		disabled: {
			type: Boolean,
			default: false,
		},
		// Свойство <router-link>: поведение сопоставления активного класса по умолчанию — включающее совпадение. Установка этой опоры заставляет режим точно соответствовать маршруту
		exact: {
			type: Boolean,
			default: false,
		},
		// Свойство <router-link>: настроить активный класс CSS, применяемый, когда ссылка активна, с точным соответствием. Обычно вы хотите установить это имя класса «активный»
		exactActiveClass: {
			type: String,
		},
		// Свойство <router-link>: разрешает сопоставление только с использованием раздела пути URL-адреса, эффективно игнорируя разделы запроса и хэша
		exactPath: {
			type: Boolean,
			default: false,
		},
		// Свойство <router-link>: настройте активный класс CSS, применяемый, когда ссылка активна с точным соответствием пути. Обычно вы хотите установить это имя класса «активный»
		exactPathActiveClass: {
			type: String,
		},
		// Свойство <b-link>: обозначает целевой URL ссылки для стандартных ссылок
		href: {
			type: String,
		},
		// Свойство <b-link>: устанавливает атрибут rel для отображаемой ссылки
		rel: {
			type: String,
			default: null,
		},
		// Свойство <router-link>: установка свойства replace вызовет `router.replace()` вместо `router.push()` при нажатии, поэтому навигация не оставит записи истории
		replace: {
			type: Boolean,
			default: false,
		},
		// Свойство <b-link>: устанавливает атрибут target для отображаемой ссылки
		target: {
			type: String,
			default: '_self',
		},
		// Свойство <router-link>: обозначает целевой маршрут ссылки. При нажатии значение реквизита to будет передано в `router.push()` внутренне, поэтому значение может быть либо строкой, либо объектом дескриптора местоположения
		to: {
			type: [Object, String],
		},
		
		// event click
		click: {
			type: Function,
		},
	},
	data(){
		return {
			
		};
	},
	computed: {},
	methods: {},
	components: {
		BLink,
	},
}
</script>