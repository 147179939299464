<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Эльфы</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/elfiyka.jpg" width="200" height="300" title="Эльфийка" /> <img src="//iwstatic.g.bsrv.su/resources/avatars/big/elf.jpg" width="200" height="300" title="Эльф" />
		</p>
		<p><b>Эльфы</b> - в сущности добрые существа, никогда не претендующие на чужую собственность. Но попробуйте-ка сказать кому-то из них, что его дом плох, а жена некрасива, и тогда Вы узнаете, что такое нож в умелых руках эльфа. Держу пари, что это будут последние слова в Вашей жизни… И, кстати, если Вы думаете, что быстрее тигра нет никого, Вы ошибаетесь – всех быстрее эльф и способность увернуться от удара их главная отличительная черта. А еще эльфы отличные лесорубы, мастера кинжалов и создают прекрасные кольца.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - акробатика</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - лесоруб</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер кинжалов</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - ювелир (кольца)</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisElfs',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>