<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Государственный магазин</h3>
		</header>
		<p><b>Государственный магазин</b> - место, где можно приобрести вещи, которые не производятся и не добываются в игре.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisShop',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>