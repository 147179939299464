<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Доминирование</h3>
		</header>
		
		<h5>Описание</h5>
		<p>Проходят недели и месяцы, и вы можете обнаружить, что ваше племя медленно расползается по карте и захватывает весь мир. В сценарии завершающей стадии, основанном на доминировании, это может означать, что вы и ваши соплеменники близки к победе в мире. Как только племя достигает установленного уровня доминирования (который варьируется в зависимости от настроек мира), всем игрокам будет разослано предупреждение, информирующее их о том, что одно племя приближается к завоеванию мира. Начиная с этого момента, для доминирующего племени идет постоянная битва за достижение своей цели, а все остальные пытаются их остановить!</p>
		
		<h5>Что такое доминирование?</h5>
		<p>Доминирование рассчитывается как процент деревень игроков, принадлежащих племени. Например, если в мире насчитывается 100 000 деревень, принадлежащих игрокам, и 50 000 из них принадлежат племени, то это племя имеет 50% доминирования.</p>
		
		<h5>Условия победы</h5>
		<p>Чтобы победить в мире должны быть выполнены такие условия (условия могут отличаться в зависимости от настроек мира):</p>
		<ul>
			<li>Возраст мира должен быть больше минимального</li>
			<li>Племя должно достигнуть необходимого процента доминирования</li>
			<li>Процент доминирования должен быть удержан в течение определённого времени</li>
		</ul>
		
		<h5>Победа в мире</h5>
		<p>Предупреждение будет разослано всем игрокам, когда требования победы будут близки к выполнению. После того, как оба условия будут выполнены, игрокам будет отправлен отчет о том, что началась финальная стадия игры и когда начнётся мир. Племя, которое достигло необходимого уровня доминирования, выиграет игру.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>