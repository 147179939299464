import { createRouter, createWebHistory } from 'vue-router';

import lib from '@/lib';

import Layout from '@/layouts/Layout';

import Home from '@/pages/Home';
import Register from '@/pages/Register';
import Profile from '@/pages/Profile';
import Achievements from '@/pages/Achievements';
import Hof from '@/pages/Hof';
import Admin from '@/pages/Admin';

import News from '@/pages/News';
import NewsItem from '@/pages/NewsItem';

import Forum from '@/pages/Forum';
import Rules from '@/pages/Rules';
import Legal from '@/pages/Legal';

import Wiki from '@/pages/Wiki';
import WikiLegend from '@/pages/wiki/Legend';
import WikiOpisWorld from '@/pages/wiki/OpisWorld';
import WikiOpisZamki from '@/pages/wiki/OpisZamki';
import WikiOpisGame from '@/pages/wiki/OpisGame';
import WikiOpisReg from '@/pages/wiki/OpisReg';
import WikiCopyright from '@/pages/wiki/Copyright';
import WikiRules from '@/pages/wiki/Rules';
import WikiOpisElfs from '@/pages/wiki/OpisElfs';
import WikiOpisBastards from '@/pages/wiki/OpisBastards';
import WikiOpisValkiria from '@/pages/wiki/OpisValkiria';
import WikiOpisGnoms from '@/pages/wiki/OpisGnoms';
import WikiOpisMert from '@/pages/wiki/OpisMert';
import WikiOpisGoblins from '@/pages/wiki/OpisGoblins';
import WikiOpisTrols from '@/pages/wiki/OpisTrols';
import WikiOpisGhost from '@/pages/wiki/OpisGhost';
import WikiOpisAngels from '@/pages/wiki/OpisAngels';
import WikiOpisDemons from '@/pages/wiki/OpisDemons';
import WikiOpisGods from '@/pages/wiki/OpisGods';
import WikiOpisAcademy from '@/pages/wiki/OpisAcademy';
import WikiOpisAltar from '@/pages/wiki/OpisAltar';
import WikiOpisAuction from '@/pages/wiki/OpisAuction';
import WikiOpisBank from '@/pages/wiki/OpisBank';
import WikiOpisHouse from '@/pages/wiki/OpisHouse';
import WikiOpisDipcentre from '@/pages/wiki/OpisDipcentre';
import WikiOpisKazarma from '@/pages/wiki/OpisKazarma';
import WikiOpisKamenelomka from '@/pages/wiki/OpisKamenelomka';
import WikiOpisLesopilka from '@/pages/wiki/OpisLesopilka';
import WikiOpisShop from '@/pages/wiki/OpisShop';
import WikiOpisMasterskaya from '@/pages/wiki/OpisMasterskaya';
import WikiOpisPort from '@/pages/wiki/OpisPort';
import WikiOpisMail from '@/pages/wiki/OpisMail';
import WikiOpisRinok from '@/pages/wiki/OpisRinok';
import WikiOpisSklad from '@/pages/wiki/OpisSklad';
import WikiOpisTavern from '@/pages/wiki/OpisTavern';
import WikiOpisFerm from '@/pages/wiki/OpisFerm';
import WikiOpisFort from '@/pages/wiki/OpisFort';
import WikiOpisShahta from '@/pages/wiki/OpisShahta';
import WikiOpisWars1 from '@/pages/wiki/OpisWars1';
import WikiOpisWars2 from '@/pages/wiki/OpisWars2';
import WikiOpisWars3 from '@/pages/wiki/OpisWars3';
import WikiOpisWars4 from '@/pages/wiki/OpisWars4';
import WikiOpisWars5 from '@/pages/wiki/OpisWars5';
import WikiOpisEconom1 from '@/pages/wiki/OpisEconom1';
import WikiOpisEconom3 from '@/pages/wiki/OpisEconom3';
import WikiOpisEconom31 from '@/pages/wiki/OpisEconom31';
import WikiOpisEconom4 from '@/pages/wiki/OpisEconom4';
import WikiOpisEconom10 from '@/pages/wiki/OpisEconom10';
import WikiOpisEconom5 from '@/pages/wiki/OpisEconom5';
import WikiOpisEconom6 from '@/pages/wiki/OpisEconom6';
import WikiOpisEconom7 from '@/pages/wiki/OpisEconom7';
import WikiOpisEconom8 from '@/pages/wiki/OpisEconom8';
import WikiOpisEconom9 from '@/pages/wiki/OpisEconom9';
import WikiOpisEconom11 from '@/pages/wiki/OpisEconom11';
import WikiOpisSouz0 from '@/pages/wiki/OpisSouz0';
import WikiOpisSouz1 from '@/pages/wiki/OpisSouz1';
import WikiOpisSouz2 from '@/pages/wiki/OpisSouz2';
import WikiOpisInfo from '@/pages/wiki/OpisInfo';
import WikiOpisSclonnost from '@/pages/wiki/OpisSclonnost';
import WikiOpisShmot1 from '@/pages/wiki/OpisShmot1';
import WikiOpisShmot2 from '@/pages/wiki/OpisShmot2';
import WikiOpisShmot3 from '@/pages/wiki/OpisShmot3';
import WikiOpisShmot4 from '@/pages/wiki/OpisShmot4';
import WikiOpisShmot5 from '@/pages/wiki/OpisShmot5';
import WikiOpisShmot6 from '@/pages/wiki/OpisShmot6';
import WikiOpisShmot7 from '@/pages/wiki/OpisShmot7';
import WikiOpisShmot8 from '@/pages/wiki/OpisShmot8';
import WikiOpisShmot9 from '@/pages/wiki/OpisShmot9';
import WikiOpisUnit1 from '@/pages/wiki/OpisUnit1';
import WikiOpisUnit2 from '@/pages/wiki/OpisUnit2';
import WikiOpisUnit3 from '@/pages/wiki/OpisUnit3';
import WikiOpisUnit4 from '@/pages/wiki/OpisUnit4';
import WikiOpisUnit5 from '@/pages/wiki/OpisUnit5';
import WikiOpisUnit6 from '@/pages/wiki/OpisUnit6';
import WikiOpisUnit7 from '@/pages/wiki/OpisUnit7';
import WikiOpisUnit10 from '@/pages/wiki/OpisUnit10';
import WikiOpisUnit11 from '@/pages/wiki/OpisUnit11';
import WikiOpisUnit12 from '@/pages/wiki/OpisUnit12';
import WikiDilers from '@/pages/wiki/Dilers';
import WikiVictoryInTheWorld from '@/pages/wiki/VictoryInTheWorld';
import WikiVictoryInTheWorldSecretsOfPower from '@/pages/wiki/VictoryInTheWorldSecretsOfPower';
import WikiVictoryInTheWorldDomination from '@/pages/wiki/VictoryInTheWorldDomination';
import WikiVictoryInTheWorldTheGreatSiege from '@/pages/wiki/VictoryInTheWorldTheGreatSiege';
import WikiVictoryInTheWorldRuneVillages from '@/pages/wiki/VictoryInTheWorldRuneVillages';
import WikiVictoryInTheWorldAgeOfEnlightenment from '@/pages/wiki/VictoryInTheWorldAgeOfEnlightenment';
import WikiVictoryInTheWorldPointsOrVillageRequirements from '@/pages/wiki/VictoryInTheWorldPointsOrVillageRequirements';

import Offer from '@/pages/Offer';
import Privacy from '@/pages/Privacy';
import Test from '@/pages/Test';

import PageNotFound from '@/pages/PageNotFound';

const routes = [
	/*{
		path: '/',
		component: StartGame,
		props: {default: true, namespace: ["page", "StartGame"]}
	},
	*/
	{
		path: '/',
		component: Layout,
		children: [
			{
				path: '',
				component: Home,
			},
			{
				path: '/register',
				component: Register,
				meta: {
					guest: true,
				},
			},
			{
				path: '/profile',
				component: Profile,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/hof',
				component: Hof,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/achievements',
				component: Achievements,
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: '/admin',
				component: Admin,
				meta: {
					requiresAuth: true,
					is_admin: true,
				},
			},
			{
				path: '/news',
				component: News,
			},
			{
				path: '/news/:id',
				component: NewsItem,
			},
			{
				path: '/forum',
				component: Forum,
			},
			{
				path: '/rules',
				component: Rules,
			},
			{
				path: '/legal',
				component: Legal,
			},
			{
				path: '/wiki',
				component: Wiki,
			},
			{
				path: '/wiki/legend',
				component: WikiLegend,
			},
			{
				path: '/wiki/opis_game',
				component: WikiOpisGame,
			},
			{
				path: '/wiki/opis_world',
				component: WikiOpisWorld,
			},
			{
				path: '/wiki/opis_zamki',
				component: WikiOpisZamki,
			},
			{
				path: '/wiki/opis_reg',
				component: WikiOpisReg,
			},
			{
				path: '/wiki/copyright',
				component: WikiCopyright,
			},
			{
				path: '/wiki/rules',
				component: WikiRules,
			},
			{
				path: '/wiki/opis_elfs',
				component: WikiOpisElfs,
			},
			{
				path: '/wiki/opis_bastards',
				component: WikiOpisBastards,
			},
			{
				path: '/wiki/opis_valkiria',
				component: WikiOpisValkiria,
			},
			{
				path: '/wiki/opis_gnoms',
				component: WikiOpisGnoms,
			},
			{
				path: '/wiki/opis_mert',
				component: WikiOpisMert,
			},
			{
				path: '/wiki/opis_goblins',
				component: WikiOpisGoblins,
			},
			{
				path: '/wiki/opis_trols',
				component: WikiOpisTrols,
			},
			{
				path: '/wiki/opis_ghost',
				component: WikiOpisGhost,
			},
			{
				path: '/wiki/opis_angels',
				component: WikiOpisAngels,
			},
			{
				path: '/wiki/opis_demons',
				component: WikiOpisDemons,
			},
			{
				path: '/wiki/opis_gods',
				component: WikiOpisGods,
			},
			{
				path: '/wiki/opis_academy',
				component: WikiOpisAcademy,
			},
			{
				path: '/wiki/opis_altar',
				component: WikiOpisAltar,
			},
			{
				path: '/wiki/opis_auction',
				component: WikiOpisAuction,
			},
			{
				path: '/wiki/opis_bank',
				component: WikiOpisBank,
			},
			{
				path: '/wiki/opis_house',
				component: WikiOpisHouse,
			},
			{
				path: '/wiki/opis_dipcentre',
				component: WikiOpisDipcentre,
			},
			{
				path: '/wiki/opis_kazarma',
				component: WikiOpisKazarma,
			},
			{
				path: '/wiki/opis_kamenelomka',
				component: WikiOpisKamenelomka,
			},
			{
				path: '/wiki/opis_lesopilka',
				component: WikiOpisLesopilka,
			},
			{
				path: '/wiki/opis_shop',
				component: WikiOpisShop,
			},
			{
				path: '/wiki/opis_masterskaya',
				component: WikiOpisMasterskaya,
			},
			{
				path: '/wiki/opis_port',
				component: WikiOpisPort,
			},
			{
				path: '/wiki/opis_mail',
				component: WikiOpisMail,
			},
			{
				path: '/wiki/opis_rinok',
				component: WikiOpisRinok,
			},
			{
				path: '/wiki/opis_sklad',
				component: WikiOpisSklad,
			},
			{
				path: '/wiki/opis_tavern',
				component: WikiOpisTavern,
			},
			{
				path: '/wiki/opis_ferm',
				component: WikiOpisFerm,
			},
			{
				path: '/wiki/opis_fort',
				component: WikiOpisFort,
			},
			{
				path: '/wiki/opis_shahta',
				component: WikiOpisShahta,
			},
			{
				path: '/wiki/opis_wars1',
				component: WikiOpisWars1,
			},
			{
				path: '/wiki/opis_wars2',
				component: WikiOpisWars2,
			},
			{
				path: '/wiki/opis_wars3',
				component: WikiOpisWars3,
			},
			{
				path: '/wiki/opis_wars4',
				component: WikiOpisWars4,
			},
			{
				path: '/wiki/opis_wars5',
				component: WikiOpisWars5,
			},
			{
				path: '/wiki/opis_econom1',
				component: WikiOpisEconom1,
			},
			{
				path: '/wiki/opis_econom3',
				component: WikiOpisEconom3,
			},
			{
				path: '/wiki/opis_econom31',
				component: WikiOpisEconom31,
			},
			{
				path: '/wiki/opis_econom4',
				component: WikiOpisEconom4,
			},
			{
				path: '/wiki/opis_econom10',
				component: WikiOpisEconom10,
			},
			{
				path: '/wiki/opis_econom5',
				component: WikiOpisEconom5,
			},
			{
				path: '/wiki/opis_econom6',
				component: WikiOpisEconom6,
			},
			{
				path: '/wiki/opis_econom7',
				component: WikiOpisEconom7,
			},
			{
				path: '/wiki/opis_econom8',
				component: WikiOpisEconom8,
			},
			{
				path: '/wiki/opis_econom9',
				component: WikiOpisEconom9,
			},
			{
				path: '/wiki/opis_econom11',
				component: WikiOpisEconom11,
			},
			{
				path: '/wiki/opis_souz0',
				component: WikiOpisSouz0,
			},
			{
				path: '/wiki/opis_souz1',
				component: WikiOpisSouz1,
			},
			{
				path: '/wiki/opis_souz2',
				component: WikiOpisSouz2,
			},
			{
				path: '/wiki/opis_info',
				component: WikiOpisInfo,
			},
			{
				path: '/wiki/opis_sclonnost',
				component: WikiOpisSclonnost,
			},
			{
				path: '/wiki/opis_shmot1',
				component: WikiOpisShmot1,
			},
			{
				path: '/wiki/opis_shmot2',
				component: WikiOpisShmot2,
			},
			{
				path: '/wiki/opis_shmot3',
				component: WikiOpisShmot3,
			},
			{
				path: '/wiki/opis_shmot4',
				component: WikiOpisShmot4,
			},
			{
				path: '/wiki/opis_shmot5',
				component: WikiOpisShmot5,
			},
			{
				path: '/wiki/opis_shmot6',
				component: WikiOpisShmot6,
			},
			{
				path: '/wiki/opis_shmot7',
				component: WikiOpisShmot7,
			},
			{
				path: '/wiki/opis_shmot8',
				component: WikiOpisShmot8,
			},
			{
				path: '/wiki/opis_shmot9',
				component: WikiOpisShmot9,
			},
			{
				path: '/wiki/opis_unit1',
				component: WikiOpisUnit1,
			},
			{
				path: '/wiki/opis_unit2',
				component: WikiOpisUnit2,
			},
			{
				path: '/wiki/opis_unit3',
				component: WikiOpisUnit3,
			},
			{
				path: '/wiki/opis_unit4',
				component: WikiOpisUnit4,
			},
			{
				path: '/wiki/opis_unit5',
				component: WikiOpisUnit5,
			},
			{
				path: '/wiki/opis_unit6',
				component: WikiOpisUnit6,
			},
			{
				path: '/wiki/opis_unit7',
				component: WikiOpisUnit7,
			},
			{
				path: '/wiki/opis_unit10',
				component: WikiOpisUnit10,
			},
			{
				path: '/wiki/opis_unit11',
				component: WikiOpisUnit11,
			},
			{
				path: '/wiki/opis_unit12',
				component: WikiOpisUnit12,
			},
			{
				component: WikiDilers,
				path: '/wiki/dilers',
			},
			{
				component: WikiVictoryInTheWorld,
				path: '/wiki/victory-in-the-world',
			},
			{
				component: WikiVictoryInTheWorldSecretsOfPower,
				path: '/wiki/victory-in-the-world/secrets-of-power',
			},
			{
				component: WikiVictoryInTheWorldDomination,
				path: '/wiki/victory-in-the-world/domination',
			},
			{
				component: WikiVictoryInTheWorldTheGreatSiege,
				path: '/wiki/victory-in-the-world/the-great-siege',
			},
			{
				component: WikiVictoryInTheWorldRuneVillages,
				path: '/wiki/victory-in-the-world/rune-villages',
			},
			{
				component: WikiVictoryInTheWorldAgeOfEnlightenment,
				path: '/wiki/victory-in-the-world/age-of-enlightenment',
			},
			{
				component: WikiVictoryInTheWorldPointsOrVillageRequirements,
				path: '/wiki/victory-in-the-world/points-or-village-requirements',
			},
			{
				path: '/offer',
				component: Offer,
			},
			{
				path: '/privacy',
				component: Privacy,
			},
		],
	},
	{
		path: '/test',
		component: Test,
	},
	{
		path: '/logout',
		beforeEnter(to, from, next){
			storeInstance.state.app.auth.doLogout().then(() => {
				next({path: '/'});
			});
		},
	},
	{
		path: '/:pathMatch(.*)*',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
	{
		path: '/:pathMatch(.*)',
		component: Layout,
		children: [
			{
				path: '',
				component: PageNotFound,
			},
		],
	},
];

const router = createRouter({
	history: createWebHistory(),
	routes,
});

router.beforeEach((to, from, next) => {
	storeInstance.state.app.auth.doCheckAuth().then(() => {
		if(to.matched.some(record => record.meta.requiresAuth)){
			if(!storeInstance.state.app.auth.isAuth()){
				next({
					//path: '/login',
					path: '/',
					params: {
						nextUrl: to.fullPath,
					},
				})
			} else {
				if(to.matched.some(record => record.meta.is_admin)){
					if(storeInstance.state.app.auth.isAdmin()){
						next()
					} else {
						next('/')
					}
				} else {
					next()
				}
			}
		} else if(to.matched.some(record => record.meta.guest)){
			if(storeInstance.state.app.auth.isAuth()){
				next('/')
			} else {
				next()
			}
		} else {
			next() 
		}
	});
});
/*// Не позволяет авторизованному пользователю перейти на страницу логина и перекидывает его на главную страницу
router.beforeEach((to, from, next) => {
	if (to.path == '/login' && storeInstance.state.app.auth.isAuth()) next({path: '/'})
  	else next()
})*/

export default router;