<template>
	<div class="container my-5">
		<header class="jumbotron">
			<h3>Новости</h3>
		</header>
		<div class="row">
			<div class="col-md-12">
				<b-overlay :show="newsOverlay && !newsPost" rounded="lg" variant="white">
					<div class="row">
						<div class="col-md-12">
							<news-post
								:post="newsPost"
							></news-post>
						</div>
					</div>
				</b-overlay>
			</div>
		</div>
	</div>
</template>

<style lang="css" scoped>
</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import NewsPost from '@/components/NewsPost.vue';
import BOverlay from '@/components/bootstrap/overlay.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'News Item | IW'});
	},
	data: () => ({
		newsOverlay: false,
		
		newsPostsSearchText: '',
		
		newsPost: [],
	}),
	computed: {},
	methods: {
		async loadPostData(id){
			this.newsOverlay = true;
			await fetch('/api/news/'+id)
				.then(stream => stream.json())
				.then(item => {
					item.url = '/news/'+item.id;
					item.poster = '//iwstatic.g.bsrv.su/img/news/'+item.id+'/'+item.poster;
					item.datetime = new Date(item.ugmtime_public).format('d.m.Y');
					item.commentsText = parseInt(item.comments) > 0 ? item.comments+' '+lib.decl1(item.comments, ['комментарий', 'комментария', 'комментариев']) : 'Комментариев нет';
					item.viewsText = parseInt(item.views) > 0 ? item.views+' '+lib.decl1(item.views, ['просмотр', 'просмотра', 'просмотров']) : 'Просмотров нет';
					item.likesText = parseInt(item.likes) > 0 ? item.likes+' '+lib.decl1(item.likes, ['лайк', 'лайка', 'лайков']) : 'Лайков нет';
					
					this.newsPost = item;
					
					setTimeout(() => {
						this.newsOverlay = false;
					}, 1000);
				})
				.catch(error => console.error(error));
		},
	},
	components: {
		NewsPost,
		BOverlay,
	},
	mounted(){
		console.log(this.$route.params.id);
		this.loadPostData(this.$route.params.id);
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>