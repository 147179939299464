<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Рунные деревни</h3>
		</header>
		
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/img/wiki/runestone.png" />
		</p>
		<h5>Описание</h5>
		<p><i>Легенда была забыта на века. Погребённая в пыльных томах и тяжёлых книгах. И только холодными зимними вечерами у костра её вспоминают старики, чтобы согреть сердца и души. Эта история о судьбах, неповиновении и предательстве; в ней говорится о местах, где содержится великая сила. В этих местах расположены магические руны, вырезанные в незапамятные времена. Они наделены силой уничтожать; могущественные и смертоносные. Слишком опасные для смертных и поэтому спрятанные создателями, чтобы появиться во времена борьбы и большой нужды. Чтобы найти могущественного правителя; чтобы завершить войны и восстановить мир.​</i></p>
		<p>Миры сами по себе такие же, как и другие миры, которые вы хорошо знаете, поэтому вам пригодится ваш опыт и стратегия. Но, как мы уже говорили, в этих мирах будет захватывающий поворот!</p>
		<p>Спустя какое-то время на карте будут появляться мистические рунные деревни, которые нужно захватить. Управление ими - ключ к победе. Но не всё так просто, как может показаться на первый взгляд! Вам предстоит пройти несколько испытаний.</p>
		<p>Первым испытанием будет победа над защитниками деревни; вам предстоит сразиться с воинами древнего культа, которые поклялись защищать секрет рун! Второе испытание - удержание деревни. Руны ослабляют всех воинов, которые находятся рядом с ними, защитить эти деревни непросто и вам потребуются могущественные союзники, чтобы добиться успеха. Третьим испытанием будет захват и удержание необходимого количества деревень, чтобы доказать свою силу! Племенам нужно будет не просто захватить достаточно деревень, а 60% <b>рунных деревень в каждом из заселённых континентов</b> (процент может отличаться в разных мирах).</p>
		<p><i>Пример:</i> -Условие захватить и удержать 60% рунных деревень. -В мире есть 4 континента, в каждом из них есть по 3 рунные деревни. Итого 12 рунных деревень в мире. -Чтобы победить племя должно захватить по 2 рунные деревни в каждом континенте. Итого 4*2 = 8 рунных деревень</p>
		<p>Племя, которое сможет пройти эти испытания и удержит необходимый процент рунных деревень в каждом из континентов в течение 14 дней будет наделено силой рун и победит в мире!</p>
		
		<h5>Фаза подготовки</h5>
		<p>В начале игра очень похожа на классическую Войну племён, которую вы все знаете и любите. Вы строите свои деревни, нанимаете войска, сражаетесь с врагами, расширяете территорию. Но затем появляются рунные деревни!</p>
		
		<h5>Фаза захвата</h5>
		<p>С появлением рунных деревень игра меняется. Теперь племенам нужно захватывать рунные деревни во всех континентах. Мудрые лидеры, конечно примут это во внимание заранее и подготовят стратегию ещё на фазе подготовки. небольшие племена тоже получат преимущества и новые возможности, они смогут координировать свои действия, чтобы отбивать рунные деревни у больших племён.</p>
		<p>В начале этой фазы племена будут сфокусированы на том, чтобы победить защитников деревни, но в конце основаня задача изменится и племена должны будут сконцентрироваться на защите деревень. Из-за штрафа для защитников (-50% сила защиты) сделать это будет всё тяжелее по мере захвата большего количества деревень. Вам нужно будет следить за передвижениями войск противника и перебрасывать войска защиты соответствующим образом. Потребуется бесперебойное снабжение войсками для защиты, чтобы удержать необходимое количество рунных деревень.</p>
		
		<h5>Фаза удержания</h5>
		<p>Как только племя захватит необходимое количество рунных деревень во всех континентах, начнётся обратный отсчёт. Когда он завершится, племя получит победу! Во время этой фазы, доминирующее племя должно сделать выбор: сконцентрироваться на защите захваченных деревень или предпринять рискованную попытку захватить ещё больше рунных деревень? Но и у остальных племён появится набор стратегий на выбор. Наиболее очевидной из них, конечно же является попытка отбить рунные деревни. Но, также, возможны и альтернативные стратегии. Например, нападение на центры найма войск, чтобы помешать бесперебойной поставке новых защитников рунных деревень и ослабить их защиту. Решение за вами!</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>