<template>
	<div>
		<servers v-if="authModel.isAuth()"></servers>
		<start-game v-else></start-game>
	</div>
</template>

<style lang="css" scoped>

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

import Servers from '@/components/Servers.vue';
import StartGame from '@/components/StartGame.vue';

export default {
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Home | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {
		
	},
	components: {
		Servers,
		StartGame,
	},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
}
</script>