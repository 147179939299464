<template>
	<component
		:is="wrapTag"
		class="b-overlay-wrap position-relative"
		:aria-busy="show"
		v-if="!noWrap"
	>
		<slot></slot>
		<b-overlay-inner
			:bgColor="bgColor"
			:blur="blur"
			:fixed="fixed"
			:noCenter="noCenter"
			:noFade="noFade"
			:opacity="opacity"
			:overlayTag="overlayTag"
			:rounded="rounded"
			:spinnerSmall="spinnerSmall"
			:spinnerType="spinnerType"
			:spinnerVariant="spinnerVariant"
			:variant="variant"
			:zIndex="zIndex"
			v-if="show"
		>
			<slot name="overlay"></slot>
		</b-overlay-inner>
	</component>
	<b-overlay-inner
		:bgColor="bgColor"
		:blur="blur"
		:fixed="fixed"
		:noCenter="noCenter"
		:noFade="noFade"
		:opacity="opacity"
		:overlayTag="overlayTag"
		:rounded="rounded"
		:spinnerSmall="spinnerSmall"
		:spinnerType="spinnerType"
		:spinnerVariant="spinnerVariant"
		:variant="variant"
		:zIndex="zIndex"
		v-if="noWrap && show"
	>
		<slot name="overlay"></slot>
	</b-overlay-inner>
</template>

<style lang="scss" scoped>

</style>

<script>
import BOverlayInner from '@/components/bootstrap/overlayInner.vue';

export default {
	name: 'BOverlay',
	props: {
		// Цвет CSS для использования в качестве цвета фона непрозрачного наложения. Если установлено, переопределяет реквизит `variant`
		bgColor: {
			type: String,
		},
		// Значение фонового фильтра размытия CSS. Не забудьте включить единицы CSS. Не поддерживается в IE 11. Установите значение null или пустую строку, чтобы отключить размытие
		blur: {
			type: String,
			default: '2px',
		},
		// Когда установлено свойство `no-wrap`, будет использоваться фиксированное позиционирование вместо абсолютного позиционирования. Удобно, если вы хотите скрыть всю страницу приложения
		fixed: {
			type: Boolean,
			default: false,
		},
		// Если установлено, отключает вертикальное и горизонтальное центрирование содержимого наложения
		noCenter: {
			type: Boolean,
			default: false,
		},
		// Отключает плавный переход наложения
		noFade: {
			type: Boolean,
			default: false,
		},
		// Отключено создание элемента-оболочки и игнорирование слота по умолчанию. Требуется, чтобы `<b-overlay>` был помещен в элемент с заданной относительной позицией
		noWrap: {
			type: Boolean,
			default: false,
		},
		// Непрозрачность фона наложения. Допустимый диапазон: от «0» до «1»
		opacity: {
			type: [Number, String],
			default: 0.85,
		},
		// Тег элемента для использования в качестве элемента наложения
		overlayTag: {
			type: String,
			default: 'div',
		},
		// Примените округление к наложению, чтобы оно соответствовало маршрутизации вашего контента. Допустимые значения: «true», «sm», «lg», «circle», «pill», «top», «right», «bottom» или «left»
		rounded: {
			type: [Boolean, String],
			default: false,
		},
		// Когда установлено, показывает наложение
		show: {
			type: Boolean,
			default: false,
		},
		// Если установлено, отображает счетчик по умолчанию в меньшем размере
		spinnerSmall: {
			type: Boolean,
			default: false,
		},
		// Тип счетчика по умолчанию для отображения. Текущие поддерживаемые типы: «граница» и «рост»
		spinnerType: {
			type: String,
			default: 'border',
		},
		// Применяет один из цветовых вариантов темы Bootstrap к счетчику по умолчанию. По умолчанию используется текущий цвет шрифта
		spinnerVariant: {
			type: String,
		},
		// Вариант цвета фоновой темы для использования в качестве фона наложения
		variant: {
			type: String,
			default: 'light',
		},
		// Тег элемента, используемый для всего элемента-оболочки. Не имеет эффекта, если установлен параметр `no-wrap`
		wrapTag: {
			type: String,
			default: 'div',
		},
		// Значение Z-индекса для применения к наложению. Возможно, вам придется увеличить это значение в соответствии с вашим контентом или местом размещения
		zIndex: {
			type: [Number, String],
			default: 10,
		},
	},
	data(){
		return {
			
		};
	},
	computed: {},
	methods: {},
	components: {
		BOverlayInner,
	},
}
</script>