<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Гоблины</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/goblina.jpg" width="200" height="300" title="Гоблина" /> <img src="//iwstatic.g.bsrv.su/resources/avatars/big/goblin.jpg" width="200" height="300" title="Гоблин" />
		</p>
		<p><b>Гоблины</b> - мелкие и противные существа, способные нападать только стаями, это ошибочное мнение, за что и поплатись, многие из рас которые с ними воевали. Ходят упорные слухи и суждения, что это за раса и откуда она взялась. Одни считают, что это отрешенные Ангелы, другие что это проклятые Демоны, в наказание лишённые своей силы и знаний, и отправленные искупать свои грехи на острова. Кто знает, может это и так. Но Вы не найдёте ни на одном острове, лучше мастера в сражение на копьях. И даже Величественные <router-link to="/wiki/opis-elfs">эльфы</router-link> порой поражаются их умениями обращаться с луками. В бою гоблины предпочитают копья, одноручные топоры и маленькие круглые щиты, обтянутые кожей болотных рептилий.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - мускулатура</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - камнелом</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер пик</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - ювелир (серьги)</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisGoblins',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>