<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Гномы</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/gnomma.jpg" width="200" height="300" title="Гномма" /> <img src="//iwstatic.g.bsrv.su/resources/avatars/big/gnom.jpg" width="200" height="300" title="Гном" />
		</p>
		<p><b>Гномы</b> - одна из немногих рас островов, помешанная на защите и броне. Много столетий назад, когда моря вышли из берегов и с неба на землю обрушились звёзды, их предки ушли под землю. Проходил года, столетия, менялся климат, очертание берегов и океанов, менялись и они. Они стали ниже ростом, но шире в плечах. Они потеряли грацию и ловкость, но приобрели силы и выносливость, с которой должны считаться даже <router-link to="/wiki/opis_trols">Тролли</router-link>. В глубоких подземельях стали неудобны луки и они изобрели арбалеты. Поговаривают, что их предками были <router-link to="/wiki/opis_elfs">эльфы</router-link>, но те и другие это отрицают и силой оружия пытаются доказать обратное. Лучшая поговорка Гномов: «хороший эльф – мертвый эльф». Гномы являются первоклассными мастерами щитов, и не многие с ними могут потягаться в обращении с боевыми топорами.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - мускулатура</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - шахтер</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер топоров</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - мастер щитов</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisGnoms',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>