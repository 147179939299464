<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Бастарды</h3>
		</header>
		<p class="text-center">
			<img src="//iwstatic.g.bsrv.su/resources/avatars/big/bastard.jpg" width="200" height="300" title="Бастард" />
		</p>
		<p><b>Бастарды</b> - в древние времена, когда острова только зарождались и все расы боролись за выживание на этой земле, возникали смешанные браки между <router-link to="/wiki/opis_elfs">Эльфами</router-link> и <router-link to="/wiki/opis_gnoms">Гномами</router-link>, <router-link to="/wiki/opis_trols">Троллями</router-link> и <router-link to="/wiki/opis_goblins">Гоблинами</router-link>. И в ту далёкую пору на это никто не обращал внимания. Но вот пришла новая эра, ознаменовавшая себя появлением новой расы – отверженных, они же Бастарды. Чем они владеют и что они умеют не знает никто. Они ненавидят и уничтожают всех на своем пути, кроме себе подобных. С ними предпочитают вообще не связываться, ведь никому не хочется умереть от их разящего меча посланного из темноты.</p>
		<p><b>Специализация (отличительные особенности расы):</b></p>
		<p>Боевой навык - ярость</p>
		<p><em>Описание навыка</em></p>
		<p>Экономический навык - фермер</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузнеца (атака) - мастер топоров</p>
		<p><em>Описание навыка</em></p>
		<p>Навык кузница (защита) - ювелир (серьги)</p>
		<p><em>Описание навыка</em></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisBastards',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>