<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Победа в мире</h3>
		</header>
		
		<h5>Сценарии победы в мирах</h5>
		<p>Есть несколько факторов, которые могут определить, когда мир будет закрыт. Условия победы в мирах могут сильно различаться, поэтому по мере развития мира мы советуем вам проверить требования к победе в мире, в котором вы играете. В игре есть несколько сценариев победы в мире ниже вы найдёте подробную информацию о них.</p>
		<p>В некоторых мирах, особенно в более старых, нет автоматической системы завершения мира. В этих мирах могут быть другие требования или условия для победы в мире. Более точную информацию о требованиях для победы смотрите в настройках мира или в официальном объявлении об открытии мира.</p>
		
		<h5>Секреты могущества</h5>
		<p>Итак, вы построили войска, захватили врагов, присоединились к могущественному племени и наводите ужас на менее удачливых игроков, где бы они не находились. Что дальше? По прошествии определенного времени с начала старта, в варварках по всему миру появятся «Секреты Могущества». Эти секреты – изобретения и открытия, которые приведут ваш мир в новую эру. И они станут главными целями игры: Племя, сумевшее получить полный набор Секретов за определенное время станет доминантной силой в новой эре, и таким образом, победит в игре.</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/secrets-of-power">Секреты могущества</router-link></p>
		
		<h5>Доминирование</h5>
		<p>Проходят недели и месяцы, и вы можете обнаружить, что ваше племя медленно расползается по карте и захватывает весь мир. В сценарии завершающей стадии, основанном на доминировании, это может означать, что вы и ваши соплеменники близки к победе в мире. Как только племя достигает установленного уровня доминирования (который варьируется в зависимости от настроек мира), всем игрокам будет разослано предупреждение, информирующее их о том, что одно племя приближается к завоеванию мира. Начиная с этого момента, для доминирующего племени идет постоянная битва за достижение своей цели, а все остальные пытаются их остановить!</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/domination">Доминирование</router-link></p>
		
		<h5>Великая осада</h5>
		<p>Великая осада - это сценарий «Короля горы». В середине мира есть большой город, который состоит из нескольких деревень, которые игрок может захватить. Эти деревни называются районами и дают племени контролирующего игрока определенное количество очков влияния. Чтобы завоевать мир, племени нужно будет достичь определенного количества очков.</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/the-great-siege">Великая осада</router-link></p>
		
		<h5>Рунные деревни</h5>
		<p>Миры сами по себе такие же, как и другие миры, которые вы хорошо знаете, поэтому вам пригодится ваш опыт и стратегия. Но, как мы уже говорили, в этих мирах будет захватывающий поворот!</p>
		<p>Спустя какое-то время на карте будут появляться мистические рунные деревни, которые нужно захватить. Управление ими - ключ к победе. Но не всё так просто, как может показаться на первый взгляд! Вам предстоит пройти несколько испытаний.</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/rune-villages">Рунные деревни</router-link></p>
		
		<h5>Эпоха просвещения</h5>
		<p>«Эпоха просвещения» - это сценарий победы в мире, который был представлен в июле 2019 года. Здесь игрокам или племенам необходимо завоевать одну из специальных университетских деревень и обновить университет до определенного уровня. Как только племя или игрок построят последний уровень Университета в этой специальной деревне, мир закроется.</p>
		<p>Чтобы быстрее достичь этой цели, игроки племени должны работать вместе, чтобы отправлять ресурсы в деревню. Идея этого сценария заключается в том, чтобы создать и модернизировать «Чудо света» как условие победы.</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/age-of-enlightenment">Эпоха просвещения</router-link></p>
		
		<h5>Требования по очкам или деревням</h5>
		<p>Этот финальный сценарий может применяться к племени или игроку, в зависимости от настроек мира. Сценарий доминирования игрока иногда используется в высокоскоростных мирах, но редко встречается в мирах, играемых на более медленной скорости. В обоих случаях, чтобы выиграть мир, игрок или племя должны набрать минимальное количество очков и минимальное количество деревень. Затем они должны удержать это количество в течение определенного периода времени. Если процент доминирования игрока или племени опускается ниже требований победы, сценарий будет отменен, и они должны начать процесс заново.</p>
		<p>Подробнее читайте в основной статье: <router-link to="/wiki/victory-in-the-world/points-or-village-requirements">Требования по очкам или деревням</router-link></p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>