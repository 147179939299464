<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Требования по очкам или деревням</h3>
		</header>
		
		<h5>Описание</h5>
		<p>Этот финальный сценарий может применяться к племени или игроку, в зависимости от настроек мира. Сценарий доминирования игрока иногда используется в высокоскоростных мирах, но редко встречается в мирах, играемых на более медленной скорости. В обоих случаях, чтобы выиграть мир, игрок или племя должны набрать минимальное количество очков и минимальное количество деревень. Затем они должны удержать это количество в течение определенного периода времени. Если процент доминирования игрока или племени опускается ниже требований победы, сценарий будет отменен, и они должны начать процесс заново.</p>
		
		<h5>Требования для победы</h5>
		<p>Чтобы племя или игрок выиграли мир, должны быть выполнены следующие условия (точные требования зависят от настроек мира):</p>
		<ul>
			<li>Игрок / племя должно соответствовать минимальному количеству очков</li>
			<li>Игрок / племя должно соответствовать минимальным требованиям деревень</li>
			<li>Игрок / племя должны постоянно выполнять оба вышеуказанных условия в течение определенного периода времени</li>
		</ul>
		
		<h5>Победа в мире</h5>
		<p>Предупреждение будет отправлено всем игрокам, когда игрок или племя выполнят условия победы. У остальных игроков мира есть определенное количество времени, в течение которого игрок / племя могут потерять очки или деревни ниже условий победы. Если игрок / племя постоянно выполняет требования в течение указанного времени, они побеждают в мире.</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiDilers',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>