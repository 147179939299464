<template>
	<div class="container pt-5 my-5">
		<header class="jumbotron">
			<h3>Склонность</h3>
		</header>
		<p><b>Путь Света</b></p>
		<p class="text-right"><em>Вот первая заповедь Воина Света: вычеркни из книги твоей жизни то, что заносил в нее до сей поры - беспокойство, неуверенность, ложь. А на место вычеркнутого впиши лишь одно слово - "отвага". Отправляясь в путь с этим словом, следуя этим путем с верой в Бога, ты достигнешь цели, к которой стремишься.<br>П. Коэльо</em></p>
		<p>Путь Света. Это трудный путь, на котором Вас ждут удачи и поражения, взлеты и падения, потери и находки. Труден путь вверх и только от того, действительно ли Вы хотите достичь вершины, зависит, увидите ли Вы ее, а достигнув ее познаете ли Любовь, Прощение, Созидание... Но желания  - это еще не все. Чтобы достичь вершины Вам нужен будет Наставник, который будет указывать ориентиры, чтобы Вы не сбились с правильного пути. Таким для Воина Света является Ангел.</p>
		<p><b>Путь Тьмы</b></p>
		<p>...</p>
		<p><b>Нейтрал</b></p>
		<p>...</p>
		<p>Перед тем, как выбрать свой путь, обязательно спросите себя: "К чему я стремлюсь в этом мире?". Только после того, как Вы сможете ответить на этот вопрос, следует выбирать склонность...</p>
	</div>
</template>

<style lang="css">

</style>

<script>
import lib from '@/lib';
import { useMeta } from 'vue-meta';

export default {
	name: 'WikiOpisSclonnost',
	mixins: lib.mixins,
	setup(){
		useMeta({title: 'Wiki | IW'});
	},
	data: () => ({
		
    }),
	computed: {},
    methods: {},
	components: {},
	beforeMount(){
		window.scrollTo(0, 0);
	},
	beforeUnmount(){},
	beforeRouteUpdate(to, from, next){
		next();
		window.scrollTo(0, 0);
	},
};
</script>